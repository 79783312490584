/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ComplianceRiskAssessment = () => {
  // Compliance areas and requirements
  const complianceAreas = {
    employment_law: {
      title: "Employment Law",
      description: "Core employment law compliance requirements",
      requirements: {
        hiring_practices: {
          name: "Hiring Practices",
          questions: [
            "Do you have standardized job applications that comply with state/federal laws?",
            "Are interview questions reviewed to avoid discriminatory topics?",
            "Do you conduct proper employment eligibility verification (I-9)?",
            "Are background check procedures compliant with FCRA requirements?"
          ],
          impact: "high",
          resources: [
            "EEOC Guidelines",
            "State Employment Laws",
            "I-9 Requirements",
            "FCRA Regulations"
          ]
        },
        wage_hour: {
          name: "Wage & Hour Compliance",
          questions: [
            "Are employees properly classified (exempt vs. non-exempt)?",
            "Do you maintain accurate time records for non-exempt employees?",
            "Are overtime calculations and payments correct?",
            "Do you comply with state-specific wage and hour requirements?"
          ],
          impact: "high",
          resources: [
            "FLSA Guidelines",
            "State Wage Laws",
            "DOL Regulations",
            "Overtime Calculation Rules"
          ]
        },
        termination_procedures: {
          name: "Termination Procedures",
          questions: [
            "Do you have documented termination procedures?",
            "Are final paychecks issued within required timeframes?",
            "Do you conduct exit interviews?",
            "Are separation agreements reviewed by legal counsel?"
          ],
          impact: "high",
          resources: [
            "State Termination Laws",
            "Final Paycheck Requirements",
            "COBRA Regulations",
            "Release Agreement Guidelines"
          ]
        }
      }
    },
    workplace_safety: {
      title: "Workplace Safety & Health",
      description: "OSHA and workplace safety requirements",
      requirements: {
        safety_program: {
          name: "Safety Program",
          questions: [
            "Do you have a written safety program?",
            "Are safety trainings conducted regularly?",
            "Are workplace accidents properly documented and investigated?",
            "Are required safety postings displayed?"
          ],
          impact: "high",
          resources: [
            "OSHA Guidelines",
            "Safety Training Requirements",
            "Accident Reporting Procedures",
            "Required Postings List"
          ]
        },
        emergency_procedures: {
          name: "Emergency Procedures",
          questions: [
            "Do you have emergency evacuation plans?",
            "Are first aid kits readily available?",
            "Are emergency contacts posted and updated?",
            "Are fire extinguishers properly maintained?"
          ],
          impact: "medium",
          resources: [
            "Emergency Planning Guidelines",
            "First Aid Requirements",
            "Fire Safety Regulations",
            "Emergency Response Procedures"
          ]
        }
      }
    },
    benefits_compliance: {
      title: "Benefits & Leave Administration",
      description: "Benefits and leave management compliance",
      requirements: {
        benefits_administration: {
          name: "Benefits Administration",
          questions: [
            "Are benefit plans ERISA compliant?",
            "Are required notices provided to employees?",
            "Is benefits documentation properly maintained?",
            "Are ACA requirements being met?"
          ],
          impact: "high",
          resources: [
            "ERISA Guidelines",
            "ACA Requirements",
            "Benefits Documentation",
            "Required Notices List"
          ]
        },
        leave_management: {
          name: "Leave Management",
          questions: [
            "Do you have FMLA procedures in place?",
            "Are state-specific leave requirements met?",
            "Is medical leave properly documented?",
            "Are leave policies consistently applied?"
          ],
          impact: "high",
          resources: [
            "FMLA Guidelines",
            "State Leave Laws",
            "ADA Requirements",
            "Leave Documentation Templates"
          ]
        }
      }
    },
    equal_employment: {
      title: "Equal Employment & Non-Discrimination",
      description: "Equal employment opportunity and non-discrimination compliance",
      requirements: {
        eeo_policies: {
          name: "EEO Policies",
          questions: [
            "Do you have written EEO policies?",
            "Are discrimination complaints properly investigated?",
            "Is required EEO training conducted?",
            "Are reasonable accommodations properly handled?"
          ],
          impact: "high",
          resources: [
            "EEOC Guidelines",
            "Anti-Discrimination Laws",
            "Training Requirements",
            "Accommodation Procedures"
          ]
        },
        harassment_prevention: {
          name: "Harassment Prevention",
          questions: [
            "Do you have anti-harassment policies?",
            "Is harassment training conducted regularly?",
            "Are complaint procedures clearly communicated?",
            "Are investigations properly documented?"
          ],
          impact: "high",
          resources: [
            "Harassment Prevention Guidelines",
            "Training Requirements",
            "Investigation Procedures",
            "Documentation Templates"
          ]
        }
      }
    },
    recordkeeping: {
      title: "HR Documentation & Recordkeeping",
      description: "Personnel records and documentation requirements",
      requirements: {
        personnel_files: {
          name: "Personnel Files",
          questions: [
            "Are personnel files properly maintained and secured?",
            "Are medical records kept separate and confidential?",
            "Are required records retained for appropriate periods?",
            "Is access to personnel files properly restricted?"
          ],
          impact: "medium",
          resources: [
            "Record Retention Requirements",
            "Confidentiality Guidelines",
            "File Organization Best Practices",
            "Access Control Procedures"
          ]
        },
        data_security: {
          name: "Data Security",
          questions: [
            "Are employee records protected from unauthorized access?",
            "Do you have data breach response procedures?",
            "Are electronic records properly backed up?",
            "Is sensitive information properly disposed of?"
          ],
          impact: "high",
          resources: [
            "Data Security Guidelines",
            "Privacy Requirements",
            "Breach Response Procedures",
            "Record Disposal Guidelines"
          ]
        }
      }
    }
  }

  // Risk levels and descriptions
  const riskLevels = {
    high: {
      label: "High Risk",
      description: "Immediate attention required - Significant compliance gaps present",
      color: "red"
    },
    medium: {
      label: "Medium Risk",
      description: "Action needed - Some compliance gaps identified",
      color: "orange"
    },
    low: {
      label: "Low Risk",
      description: "Monitor - Minor improvements recommended",
      color: "green"
    }
  }

  // State management
  const [assessmentData, setAssessmentData] = useState({
    organization_size: "",
    industry: "",
    responses: {},
    submitted: false
  })

  // Industry options
  const industries = {
    technology: "Technology",
    healthcare: "Healthcare",
    finance: "Finance",
    retail: "Retail",
    manufacturing: "Manufacturing",
    consulting: "Consulting",
    education: "Education",
    government: "Government"
  }

  // Organization size options
  const organizationSizes = {
    "1-100": "1-100 employees",
    "101-500": "101-500 employees",
    "501-1000": "501-1,000 employees",
    "1001-5000": "1,001-5,000 employees",
    "5001-10000": "5,001-10,000 employees",
    "10000+": "10,000+ employees"
  }

  // Handle input changes
  const handleInputChange = (field, value) => {
    setAssessmentData(prev => ({
      ...prev,
      [field]: value
    }))
  }

  // Handle response changes
  const handleResponseChange = (area, requirement, question, value) => {
    setAssessmentData(prev => ({
      ...prev,
      responses: {
        ...prev.responses,
        [area]: {
          ...prev.responses[area],
          [requirement]: {
            ...prev.responses[area]?.[requirement],
            [question]: value
          }
        }
      }
    }))
  }

  // Calculate requirement risk level
  const calculateRequirementRisk = (area, requirement) => {
    const responses = assessmentData.responses[area]?.[requirement] || {}
    const questions = complianceAreas[area].requirements[requirement].questions
    
    if (Object.keys(responses).length === 0) return "high"

    const yesCount = Object.values(responses).filter(r => r === "yes").length
    const compliance = yesCount / questions.length

    if (compliance >= 0.8) return "low"
    if (compliance >= 0.6) return "medium"
    return "high"
  }

  // Calculate area risk level
  const calculateAreaRisk = (area) => {
    const requirements = complianceAreas[area].requirements
    const riskLevels = Object.keys(requirements).map(req => calculateRequirementRisk(area, req))
    
    if (riskLevels.includes("high")) return "high"
    if (riskLevels.includes("medium")) return "medium"
    return "low"
  }

  // Generate recommendations
  const generateRecommendations = () => {
    const recommendations = []

    Object.entries(complianceAreas).forEach(([area, areaData]) => {
      Object.entries(areaData.requirements).forEach(([req, reqData]) => {
        const riskLevel = calculateRequirementRisk(area, req)
        const responses = assessmentData.responses[area]?.[req] || {}
        
        reqData.questions.forEach((question, index) => {
          if (responses[index] !== "yes") {
            recommendations.push({
              area: areaData.title,
              requirement: reqData.name,
              issue: question,
              riskLevel: riskLevel,
              resources: reqData.resources
            })
          }
        })
      })
    })

    return recommendations.sort((a, b) => {
      const riskOrder = { high: 0, medium: 1, low: 2 }
      return riskOrder[a.riskLevel] - riskOrder[b.riskLevel]
    })
  }

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()
    setAssessmentData(prev => ({
      ...prev,
      submitted: true
    }))
  }

  return (
    <Layout className="compliance-risk-assessment-page">
      <SEO 
        title="HR Compliance Risk Assessment | Identify and Mitigate Risks" 
        description="Evaluate HR compliance risks and get actionable recommendations for risk mitigation."
      />
      <div className="wrapper" sx={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
        <h1 sx={{ fontSize: ['1.75rem', '2rem', '2.25rem'], mb: 4 }}>
          HR Compliance Risk Assessment
        </h1>

        {/* Description Section */}
        <div sx={{ 
          bg: 'white', 
          p: 4, 
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          mb: 4
        }}>
          <h2 sx={{ fontSize: '1.3rem', mb: 3, color: 'primary' }}>About This Tool</h2>
          <p sx={{ fontSize: '1rem', lineHeight: '1.6', mb: 3 }}>
            Identify potential HR compliance risks and get actionable recommendations for risk mitigation. 
            This assessment helps you:
          </p>
          <ul sx={{ 
            listStyle: 'disc',
            pl: 4,
            mb: 3,
            '& li': { 
              mb: 2,
              fontSize: '1rem',
              lineHeight: '1.5'
            }
          }}>
            <li>Evaluate compliance across key HR areas</li>
            <li>Identify potential compliance gaps</li>
            <li>Get prioritized recommendations</li>
            <li>Access relevant compliance resources</li>
          </ul>
        </div>

        {!assessmentData.submitted ? (
          <form onSubmit={handleSubmit}>
            {/* Organization Information */}
            <div sx={{ 
              bg: 'white', 
              p: 4, 
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              mb: 4
            }}>
              <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Organization Information</h2>
              <div sx={{ display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr'], gap: 4 }}>
                <div>
                  <label htmlFor="organization_size" sx={{ display: 'block', mb: 2 }}>
                    Organization Size
                  </label>
                  <select
                    id="organization_size"
                    value={assessmentData.organization_size}
                    onChange={(e) => handleInputChange('organization_size', e.target.value)}
                    required
                    sx={{
                      width: '100%',
                      p: 2,
                      border: '1px solid',
                      borderColor: 'gray.3',
                      borderRadius: '4px',
                      backgroundColor: 'white',
                      color: 'text',
                      fontSize: '1rem',
                      appearance: 'none',
                      backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22currentColor%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%226%209%2012%2015%2018%209%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E")',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right 8px center',
                      backgroundSize: '16px',
                      '&:focus': {
                        outline: 'none',
                        borderColor: 'primary',
                        boxShadow: '0 0 0 2px rgba(0,119,255,0.1)'
                      },
                      '&:hover': {
                        borderColor: 'primary'
                      }
                    }}
                  >
                    <option value="">Select size</option>
                    {Object.entries(organizationSizes).map(([key, label]) => (
                      <option key={key} value={key}>{label}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label htmlFor="industry" sx={{ display: 'block', mb: 2 }}>
                    Industry
                  </label>
                  <select
                    id="industry"
                    value={assessmentData.industry}
                    onChange={(e) => handleInputChange('industry', e.target.value)}
                    required
                    sx={{
                      width: '100%',
                      p: 2,
                      border: '1px solid',
                      borderColor: 'gray.3',
                      borderRadius: '4px',
                      backgroundColor: 'white',
                      color: 'text',
                      fontSize: '1rem',
                      appearance: 'none',
                      backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22currentColor%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%226%209%2012%2015%2018%209%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E")',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right 8px center',
                      backgroundSize: '16px',
                      '&:focus': {
                        outline: 'none',
                        borderColor: 'primary',
                        boxShadow: '0 0 0 2px rgba(0,119,255,0.1)'
                      },
                      '&:hover': {
                        borderColor: 'primary'
                      }
                    }}
                  >
                    <option value="">Select industry</option>
                    {Object.entries(industries).map(([key, label]) => (
                      <option key={key} value={key}>{label}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {/* Compliance Areas */}
            {Object.entries(complianceAreas).map(([areaKey, area]) => (
              <div key={areaKey} sx={{ 
                bg: 'white', 
                p: 4, 
                borderRadius: '8px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                mb: 4
              }}>
                <h2 sx={{ fontSize: '1.5rem', mb: 2, color: 'primary' }}>{area.title}</h2>
                <p sx={{ fontSize: '1rem', mb: 4, color: 'gray.7' }}>{area.description}</p>
                
                {Object.entries(area.requirements).map(([reqKey, requirement]) => (
                  <div key={reqKey} sx={{ mb: 4 }}>
                    <h3 sx={{ fontSize: '1.2rem', mb: 3 }}>{requirement.name}</h3>
                    
                    {requirement.questions.map((question, index) => (
                      <div key={`${areaKey}-${reqKey}-${index}`} sx={{ mb: 3 }}>
                        <label sx={{ display: 'block', mb: 2 }}>
                          {question}
                        </label>
                        <select
                          value={assessmentData.responses[areaKey]?.[reqKey]?.[index] || ""}
                          onChange={(e) => handleResponseChange(areaKey, reqKey, index, e.target.value)}
                          required
                          sx={{
                            width: '100%',
                            p: 2,
                            border: '1px solid',
                            borderColor: 'gray.3',
                            borderRadius: '4px',
                            backgroundColor: 'white',
                            color: 'text',
                            fontSize: '1rem',
                            appearance: 'none',
                            backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22currentColor%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%226%209%2012%2015%2018%209%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E")',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right 8px center',
                            backgroundSize: '16px',
                            '&:focus': {
                              outline: 'none',
                              borderColor: 'primary',
                              boxShadow: '0 0 0 2px rgba(0,119,255,0.1)'
                            },
                            '&:hover': {
                              borderColor: 'primary'
                            }
                          }}
                        >
                          <option value="">Select response</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                          <option value="partial">Partial/In Progress</option>
                          <option value="unknown">Unknown/Not Sure</option>
                        </select>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}

            {/* Submit Button */}
            <div sx={{ 
              display: 'flex', 
              justifyContent: 'flex-end',
              mb: 4
            }}>
              <button
                type="submit"
                sx={{
                  px: 4,
                  py: 2,
                  bg: 'primary',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontSize: '1rem',
                  '&:hover': {
                    bg: 'primaryDark'
                  }
                }}
              >
                Generate Risk Assessment
              </button>
            </div>
          </form>
        ) : (
          <>
            {/* Results Section */}
            <div sx={{ 
              bg: 'white', 
              p: 4, 
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              mb: 4
            }}>
              <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Risk Assessment Results</h2>
              
              {/* Area Risk Levels */}
              <div sx={{ 
                display: 'grid', 
                gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'],
                gap: 4,
                mb: 4
              }}>
                {Object.entries(complianceAreas).map(([area, data]) => (
                  <div key={area} sx={{
                    p: 3,
                    border: '1px solid',
                    borderColor: 'gray.2',
                    borderRadius: '4px'
                  }}>
                    <h4 sx={{ fontSize: '1.1rem', mb: 2 }}>{data.title}</h4>
                    <p sx={{ 
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                      color: riskLevels[calculateAreaRisk(area)].color
                    }}>
                      {riskLevels[calculateAreaRisk(area)].label}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {/* Recommendations Section */}
            <div sx={{ 
              bg: 'white', 
              p: 4, 
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              mb: 4
            }}>
              <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Risk Mitigation Recommendations</h2>
              
              {/* High Risk Items */}
              {generateRecommendations().filter(r => r.riskLevel === "high").length > 0 && (
                <div sx={{ mb: 4 }}>
                  <h3 sx={{ 
                    fontSize: '1.2rem', 
                    color: 'red',
                    mb: 3
                  }}>
                    High Risk Items - Immediate Action Required
                  </h3>
                  {generateRecommendations()
                    .filter(r => r.riskLevel === "high")
                    .map((recommendation, index) => (
                      <div key={`high-${index}`} sx={{ mb: 4 }}>
                        <h4 sx={{ fontSize: '1.1rem', mb: 2 }}>
                          {recommendation.area} - {recommendation.requirement}
                        </h4>
                        <p sx={{ mb: 2, fontWeight: 'bold' }}>Issue: {recommendation.issue}</p>
                        <div sx={{ mb: 2 }}>
                          <strong>Relevant Resources:</strong>
                          <ul sx={{ 
                            listStyle: 'disc',
                            pl: 4,
                            '& li': { mb: 1 }
                          }}>
                            {recommendation.resources.map((resource, resourceIndex) => (
                              <li key={`high-${index}-${resourceIndex}`}>{resource}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                </div>
              )}

              {/* Medium Risk Items */}
              {generateRecommendations().filter(r => r.riskLevel === "medium").length > 0 && (
                <div sx={{ mb: 4 }}>
                  <h3 sx={{ 
                    fontSize: '1.2rem', 
                    color: 'orange',
                    mb: 3
                  }}>
                    Medium Risk Items - Action Needed
                  </h3>
                  {generateRecommendations()
                    .filter(r => r.riskLevel === "medium")
                    .map((recommendation, index) => (
                      <div key={`medium-${index}`} sx={{ mb: 4 }}>
                        <h4 sx={{ fontSize: '1.1rem', mb: 2 }}>
                          {recommendation.area} - {recommendation.requirement}
                        </h4>
                        <p sx={{ mb: 2, fontWeight: 'bold' }}>Issue: {recommendation.issue}</p>
                        <div sx={{ mb: 2 }}>
                          <strong>Relevant Resources:</strong>
                          <ul sx={{ 
                            listStyle: 'disc',
                            pl: 4,
                            '& li': { mb: 1 }
                          }}>
                            {recommendation.resources.map((resource, resourceIndex) => (
                              <li key={`medium-${index}-${resourceIndex}`}>{resource}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                </div>
              )}

              {/* Low Risk Items */}
              {generateRecommendations().filter(r => r.riskLevel === "low").length > 0 && (
                <div>
                  <h3 sx={{ 
                    fontSize: '1.2rem', 
                    color: 'green',
                    mb: 3
                  }}>
                    Low Risk Items - Monitor
                  </h3>
                  {generateRecommendations()
                    .filter(r => r.riskLevel === "low")
                    .map((recommendation, index) => (
                      <div key={`low-${index}`} sx={{ mb: 4 }}>
                        <h4 sx={{ fontSize: '1.1rem', mb: 2 }}>
                          {recommendation.area} - {recommendation.requirement}
                        </h4>
                        <p sx={{ mb: 2, fontWeight: 'bold' }}>Issue: {recommendation.issue}</p>
                        <div sx={{ mb: 2 }}>
                          <strong>Relevant Resources:</strong>
                          <ul sx={{ 
                            listStyle: 'disc',
                            pl: 4,
                            '& li': { mb: 1 }
                          }}>
                            {recommendation.resources.map((resource, resourceIndex) => (
                              <li key={`low-${index}-${resourceIndex}`}>{resource}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>

            {/* Action Buttons */}
            <div sx={{ 
              display: 'flex', 
              gap: 3,
              justifyContent: 'flex-end',
              mb: 4
            }}>
              <button
                type="button"
                onClick={() => window.print()}
                sx={{
                  px: 4,
                  py: 2,
                  bg: 'primary',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontSize: '1rem',
                  '&:hover': {
                    bg: 'primaryDark'
                  }
                }}
              >
                Print Assessment
              </button>
              <button
                type="button"
                onClick={() => setAssessmentData(prev => ({ ...prev, submitted: false }))}
                sx={{
                  px: 4,
                  py: 2,
                  bg: 'white',
                  color: 'primary',
                  border: '1px solid',
                  borderColor: 'primary',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontSize: '1rem',
                  '&:hover': {
                    bg: 'gray.1'
                  }
                }}
              >
                Start New Assessment
              </button>
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

export default ComplianceRiskAssessment 